import { Space } from "antd";
import "./App.css";
import AppHeader from "./components/AppHeader";
import AppFooter from "./components/AppFooter";
import PageContent from "./components/PageContent";
import SideMenu from "./components/SideMenu";

function App() {
  return (
    <div className="App max-w-[1530px] mx-auto">
      <AppHeader />

      <div className="flex px-10 w-full min-h-[550px]">
        <SideMenu />
        <PageContent />
      </div>

      <AppFooter />
    </div>
  );
}

export default App;
