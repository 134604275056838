import { Badge, Image, Space, Link, Typography } from "antd";
import React from "react";

const AppFooter = () => {
  return (
    <div className="py-10 bg-neutral-100 text-center flex justify-evenly border-t-[1px] border-inherit">
      <Typography className="cursor-pointer">+847-287-4288</Typography>
      <Typography className="cursor-pointer">Privacy Policy</Typography>
      <Typography className="cursor-pointer">Terms of Use</Typography>
    </div>
  );
};

export default AppFooter;
