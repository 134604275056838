import React from "react";
import AppRoutes from "../AppRoutes";

const PageContent = () => {
  return (
    <div className="w-full lg:px-8 py-10 pl-0 mt-[70px] md:mt-0 sm:pl-10 md:pl-0">
      <AppRoutes />
    </div>
  );
};

export default PageContent;
