import React from "react";
import { Badge, Input, Space, Typography } from "antd";
import { BellFilled, MailOutlined } from "@ant-design/icons";
import Logo from "../../assets/logo.png";
import { FaSearch } from "react-icons/fa";

const AppHeader = () => {
  return (
    <div className="w-full py-4 lg:px-28 px-10 flex border-b-[1px] border-inherit fixed md:relative z-50 bg-white">
      <span className="py-1">
        <img className="w-[90px] lg:w-[120px]" src={Logo} />
      </span>

      <span className="py-3 flex absolute lg:right-20 md:right-20 right-12">
        <Input
          className="w-[400px] mr-3 lg:block hidden"
          type="text"
          placeholder="Search"
        />
        <button className="bg-blue-900 hidden lg:block hover:bg-blue-900 px-4 py-3 text-white rounded-lg mr-20">
          <FaSearch />
        </button>
        <Space className="">
          <Badge count={10} dot>
            <MailOutlined className="text-[20px]" />
          </Badge>

          <Badge count={20}>
            <BellFilled className="text-[20px]" />
          </Badge>
        </Space>
      </span>
    </div>
  );
};

export default AppHeader;
