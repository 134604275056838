import {
  AppstoreOutlined,
  ShopOutlined,
  ShoppingCartOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfilePic from "../../assets/profile_pic.png";

import { IoIosArrowDropright } from "react-icons/io";
import { IoIosArrowDropleft } from "react-icons/io";

const SideMenu = () => {
  const navigate = useNavigate();
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };

  const dash = () => {
    return <div className={mobileMenu ? "block" : "hidden"}>Dashboard</div>;
  };

  return (
    <div>
      {/* Side Menu */}
      <div className="SideMenu hidden lg:block md:block xl:block">
        <div className="block text-center pt-3 pb-5">
          <img className="w-[150px]" src={ProfilePic} />
          <h3 className="text-center py-2">Luis Carter</h3>
          <button className="bg-transparent border rounded-lg px-3 py-1 text-xs">
            Edit
          </button>

          <Menu
            className="mt-4 text-left"
            onClick={(item) => {
              navigate(item.key);
            }}
            items={[
              {
                label: "Dashboard",
                icon: <AppstoreOutlined />,
                key: "/",
              },
              {
                label: "Inventory",
                icon: <ShopOutlined />,
                key: "/inventory",
              },
              {
                label: "Orders",
                icon: <ShoppingCartOutlined />,
                key: "/orders",
              },
              {
                label: "Customers",
                icon: <UserOutlined />,
                key: "/customers",
              },
            ]}
          />
        </div>
      </div>
      {/* Side Menu End */}

      {/* Mobile Menu */}

      <div
        className={`block md:hidden mobileMenu ? "block absolute left-[25px] top-[45px] bg-white pt-[26px] mt-10 z-40 text-black ease-in-out duration-500" : "hidden left-[-100%]"`}
      >
        <div
          onClick={handleMobileMenu}
          className="block md:hidden px-4 cursor-pointer text-center top-0"
        >
          {!mobileMenu ? (
            <IoIosArrowDropright className="inline-block mr-0" size={24} />
          ) : (
            <IoIosArrowDropleft className="inline-block mr-3" size={24} />
          )}
        </div>

        <div className="block text-center pt-3 pb-0">
          <Menu
            className={
              mobileMenu ? "block mt-4 text-left" : "hidden mt-4 text-left "
            }
            onClick={(item) => {
              navigate(item.key);
              handleMobileMenu();
            }}
            items={[
              {
                label: "",
                icon: <AppstoreOutlined />,
                key: "/",
              },
              {
                label: "",
                icon: <ShopOutlined />,
                key: "/inventory",
              },
              {
                label: "",
                icon: <ShoppingCartOutlined />,
                key: "/orders",
              },
              {
                label: "",
                icon: <UserOutlined />,
                key: "/customers",
              },
            ]}
          />
        </div>
      </div>
      {/* Mobile Menu End */}
    </div>
  );
};

export default SideMenu;
